<template>
  <v-container>
    <v-row>
      <v-col col="12" sm="12" md="12">
        <h2>Voluntary work or involvement in civic / non-government / people / voluntary organization/s
        </h2>
        <v-divider></v-divider>
      </v-col>
      <v-col col="12" sm="12" md="12">
        <v-btn color="primary" class="mr-4 text--white" @click="openDialog()">Add Info...</v-btn>
      </v-col>
      <v-col col="12" sm="12" md="12">
        <v-data-table :headers="headers" :items="desserts" class="elevation-1">
          <template v-slot:item = "row">
            <tr>
              <td nowrap>
                <v-btn class="mx-2" fab dark small color="primary" @click="updates(row.item)">
                  <v-icon dark>mdi-pencil</v-icon>
                </v-btn>
                <v-btn class="mx-2" fab dark small color="red" @click="deletes(row.item)">
                  <v-icon dark>mdi-delete</v-icon>
                </v-btn>
              </td>
              <td>{{row.item.Organization}}</td>
              <td>{{row.item.DFrom}}</td>
              <td>{{row.item.DTo}}</td>
              <td>{{row.item.NoHours}}</td>
              <td>{{row.item.Nature}}</td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="dialog" persistent max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">Information</span>
          </v-card-title>
          <v-card-text>
          <v-divider></v-divider>
          <v-container>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field v-model="Organization" label="Organization"  :rules="[vv.init(this.Organization), vv.required(), vv.maxlen(255)]" required outlined dense></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field v-model="DFrom" label="From" type="date" :rules="[vv.init(this.DFrom)]"    required outlined dense></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field v-model="DTo" label="To" type="date" :rules="[vv.init(this.DTo)]"   required outlined dense></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field v-model="NoHours" label="No. of hours"   :rules="[vv.init(this.NoHours), vv.required(), vv.maxlen(255)]"  required outlined dense></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field v-model="Nature" label="Nature of Work"   :rules="[vv.init(this.Nature), vv.required(), vv.maxlen(255)]" required outlined dense></v-text-field>
                </v-col>

              </v-row>
            </v-form>
          </v-container>
            
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialog = false">Close</v-btn>
            <v-btn :disabled="!valid" color="blue darken-1" text @click="savechanges()">Save</v-btn>
          </v-card-actions>
          </v-card>      
        </v-dialog>
      </v-row>
  </v-container>
</template>
<script>
import vldn from "@/js/validation";
export default {
  name: "Vwi",
  data: () => ({
    valid: false,
    formTitle: '',
    dialog: false,
    headers: [
      {
        text: 'Action',
        align: 'start',
        sortable: false,
        value: 'action',
      },
      { text: 'Name of Organization',sortable: false, value: 'Organization' },
      { text: 'From',sortable: false, value: 'DFrom' },
      { text: 'To',sortable: false, value: 'DTo' },
      { text: 'No. of Hours',sortable: false, value: 'NoHours' },
      { text: 'Position/Nature of Work',sortable: false, value: 'Nature' } ,
     
     ],
    desserts: [],
    ID: 0,
    Organization: '',
    DFrom: '',
    DTo: '',
    NoHours: '',
    Nature: '',
    vv: vldn.methods,
  }),
  componets: {
    vldn,
  },
  mounted() {
    this.$api.methods.init();
    this.getvwi();
    
  },
  methods: {
    openDialog(){
      
      this.ID = 0;
      this.dialog = true;
    },
    updates(item){
     
      this.ID = item.ID;
      this.Organization= item.Organization;
      this.DFrom= item.DFrom;
      this.DTo= item.DTo;
      this.NoHours= item.NoHours;
      this.Nature= item.Nature
     
      this.dialog = true;
    },
    getvwi(){

      this.$axios({
          method: 'get',
          url: process.env.VUE_APP_BASE_URL + 'pds/vwi',
          headers: {
            Authorization: 'Bearer' + localStorage.getItem('usertoken')
          }
         })
        .then(response => {
          if(response){
            this.desserts = response.data;
          }    
        })
        .catch(error => {
          if (error.response) {
            if(error.response.status == 500){
              //
            }
          }    
        })

    },
    deletes(item){
      this.$axios({
          method: 'post',
          url: process.env.VUE_APP_BASE_URL + 'pds/deletevwi',
          headers: {
            Authorization: 'Bearer' + localStorage.getItem('usertoken')
          },
          data: {
            ID:  item.ID
          }
         })
        .then(response => {
          if(response){
            this.getvwi();
            this.ID = 0;
            
          }    
        })
        .catch(error => {
          if (error.response) {
            if(error.response.status == 500){
              //
            }
          }    
        })
    },
    savechanges(){
      if(this.$refs.form.validate()){
        this.$axios({
          method: 'post',
          url: process.env.VUE_APP_BASE_URL + 'pds/updatevwi',
          headers: {
            Authorization: 'Bearer' + localStorage.getItem('usertoken')
          },
          data: {
            ID: this.ID,
            Organization: this.Organization,
            DFrom: this.DFrom,
            DTo: this.DTo,
            NoHours: this.NoHours,
            Nature: this.Nature,
          }
         })
        .then(response => {
          if(response){
              this.getvwi();
              this.ID = 0;
              this.Organization= '';
              this.DFrom= '';
              this.DTo= '';
              this.NoHours= '';
              this.Nature= '';
              this.dialog = false;
          }    
        })
        .catch(error => {
          if (error.response) {
            if(error.response.status == 500){
              //
            }
          }    
        })
      }
    }
  }
}
</script>
<style>
  .container{
    max-width: 80%;
  }
</style>
